import { Platform } from 'react-native'
import {API_URL_MBL, API_URL_WEB} from "@env"

if ( Platform.OS == 'web' ) {
    var VMAPI = API_URL_WEB;
} else {
    var VMAPI = API_URL_MBL;
}

export const widthDesk = 450;
export const MAPI = VMAPI;
export const MAPI2 = API_URL_MBL;

// export const primaryColor = "#9370db";
export const primaryColor = "#0C2978";
export const secondaryColor = "grey";
export const version = "1.0.25";
export const appContainer = {
    flex: 1,
    padding: 16,
}
export const appLabel = {
    fontSize: 12,
    color: 'grey'
};

export const textArea =  {
    marginTop: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: primaryColor,
    textAlignVertical: 'top'
}

export const fwBold =  {fontWeight: 'bold'};
export const textGroup =  {
    marginBottom: 10, 
    padding: 5
};
export const CONSTANT_NUMBER_1 = 'hello I am a constant';
export const CONSTANT_NUMBER_2 = 'hello I am also a constant';


// FUNCTION 
export const convertTgl = (tgl) => {
    // return tgl.toLocaleDateString()
    return tgl.toISOString().split('T')[0];
}

export const convertWaktu = (tgl) => {
    return tgl.toLocaleTimeString()
}