// In App.js in a new project

import React, {useEffect } from 'react';
import { View, Text } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import MyTabs from '../screens/MyTabs';
import AbsenCamera from '../screens/AbsenCamera';
import Login from '../screens/Login';
import { useAuth } from '../contexts/auth';
import Absen from '../screens/Absen';
import { Dimensions } from 'react-native';
import ModalAbsenPhoto from '../screens/ModalAbsenPhoto';
import Attendance from '../screens/Attendance';
import Welcome from '../screens/Welcome';


const Stack = createNativeStackNavigator();



function MyApp() {
    const { user } = useAuth();
   
  return (
    <NavigationContainer>
      <Stack.Navigator>
        {
            user ?
            <>
                <Stack.Screen name="Home" component={MyTabs} options={{ headerShown: false}} />
                <Stack.Screen name="Attendance" component={Attendance} options={{ headerShown: true}} />
                <Stack.Screen name="Absen" component={Absen} options={{ headerShown: true}} />
                <Stack.Screen name="AbsenCamera" component={AbsenCamera} options={{ headerShown: true}} />
                <Stack.Screen name="ModalAbsenPhoto" component={ModalAbsenPhoto} options={{ headerTitle: 'My Photo', headerShown: true, presentation:'fullScreenModal'}} />
            </>
            :
            <>
            <Stack.Screen name="Welcome" component={Welcome} options={{ headerShown: false}} />
            <Stack.Screen name="Login" component={Login} options={{ headerShown: false}} />
            </>

        }
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default MyApp;