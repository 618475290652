import { Camera, CameraType, ImageType } from 'expo-camera';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-native-paper';
import {  StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import AppCameraPreview from './AppCameraPreview';
import { primaryColor } from '../Constant';
import * as FaceDetector from 'expo-face-detector'
import { Platform } from 'react-native';

export default function AppCamera({ navigation }) {
    // BEFORE USE CAMERA  ON WEB PLATFORM DO DIS
    // rd /s /q node_modules\expo-camera\node_modules\react

    const [type, setType] = useState(CameraType.front);
    const [permission, requestPermission] = Camera.useCameraPermissions();

    const [isAll, setIsAll] = useState(true);
    const [isSmile, setIsSmile] = useState(false);
    const cameraRef = React.useRef();

    const [previewVisible, setPreviewVisible] = useState(false)
    const [capturedImage, setCapturedImage] = useState(null)

    useEffect( () => {
        console.log('cok navigation',navigation )
    },[])

  if (!permission) {
    // Camera permissions are still loading
    return (
        <View>
            <Text>Camera permission are still loading</Text>
        </View>
    );
  }

  if (!permission.granted) {
    // Camera permissions are not granted yet
    return (
      <View style={styles.container}>
        <Text style={{ textAlign: 'center' }}>We need your permission to show the camera</Text>
        {/* <Button onPress={requestPermission} title="grant permission" /> */}
        <Button
            style={{
                width: '50%',
                alignSelf: 'center',
                marginBottom: 10,
            }} 
            icon="camera" 
            mode="contained" 
            buttonColor={primaryColor} 
            onPress={() => {
                requestPermission()
            }}
        >
            Okay
        </Button>
      </View>
    );
  }

  function toggleCameraType() {
    setType(current => (current === CameraType.back ? CameraType.front : CameraType.back));
  }
  
  const __takePicture = async () => {
        if (!cameraRef.current) {
            console.log("oops")
            return false;
        } 
        const options = { quality: 0.2, base64: true, skipProcessing: false, scale: 1,  };
        const photo = await cameraRef.current.takePictureAsync( options )
        // console.log('photo senyum', photo && photo.base64)
        setPreviewVisible(true)
        setCapturedImage(photo)

    }

    const handleFacesDetected = ({ faces }) => {
      if ( Platform.OS == 'web' ) {
        return false;
      }
      const hasFaces = faces.length > 0;
      const hasFaceProps = hasFaces ? !!faces[0].leftEyePosition : false;
      const hasSmile = hasFaces ? !!faces[0].smilingProbability : false;

      // console.log('faces', faces)
      // console.log('hasSmile', hasSmile)

        if ( hasFaces ) {
    
          if ( hasSmile ) {
                const faceID = faces[0].faceID;
                const eyesShut = faces[0].rightEyeOpenProbability < 0.4 && faces[0].LeftEyeOpenProbability < 0.4;
                const winking = !eyesShut && (faces[0].rightEyeOpenProbability < 0.4 || faces[0].LeftEyeOpenProbability < 0.4);
                const smiling = faces[0].smilingProbability > 0.7;
                const yawAngle = faces[0].yawAngle
                setIsSmile(faces[0].smilingProbability > 0.2)
            
                if ( smiling ) {
                    console.log("USER SMILE")
                    __takePicture();
                }

            }
            // console.log('FACESSS ARAY', faces[0])
        }
    }

  return (
    <View style={styles.container}>

        {
            previewVisible && capturedImage ?
                (<AppCameraPreview photo={capturedImage} navigation={navigation} />)
            :
                (
                  <Camera style={styles.camera} type={type}
                      ref={cameraRef}
                      onFacesDetected={handleFacesDetected}
                      faceDetectorSettings={{
                        mode: FaceDetector.FaceDetectorMode.accurate,
                        detectLandmarks: isAll ? FaceDetector.FaceDetectorLandmarks.all : FaceDetector.FaceDetectorLandmarks.none,
                        runClassifications: isAll ? FaceDetector.FaceDetectorClassifications.all : FaceDetector.FaceDetectorClassifications.none,
                        minDetectionInterval: 100,
                        tracking: true,
                      }}
                  >
                      <View style={styles.buttonContainer}>
                        {/* <TouchableOpacity style={styles.button} onPress={toggleCameraType}>
                            <Text style={styles.text}>Flip Camera</Text>
                        </TouchableOpacity> */}
                        {
                          Platform.OS == 'web'?
                          <TouchableOpacity style={styles.button} onPress={__takePicture}>
                              <Text style={styles.text}>Shoot</Text>
                          </TouchableOpacity>
                          :
                          <TouchableOpacity style={styles.button} onPress={__takePicture}>
                              <Text style={styles.text}>Smile Please</Text>
                          </TouchableOpacity>
                        }
                      </View>
                  </Camera>
                )
        }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  camera: {
    flex: 1,
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: 'transparent',
    margin: 34,
    justifyContent: 'space-between',
  },
  button: {
    flex: 1,
    alignSelf: 'flex-end',
    alignItems: 'center',
  },
  text: {
    fontSize: 24,
    fontWeight: 'bold',
    color: 'white',
  },
});
