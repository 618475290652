import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { primaryColor } from '../Constant';

export const DataMenu = [
    {
        id: 1,
        name: 'CRM',
        icon: <MaterialCommunityIcons name="home-account" color={primaryColor} size={40} />,
    },
    {
        id: 2,
        name: 'Discuss',
        icon: <FontAwesome name="wechat" color={primaryColor} size={30} />,
    },
    {
        id: 3,
        name: 'Desk',
        icon: <MaterialCommunityIcons name="desk" color={primaryColor} size={30} />,
    },
    {
        id: 4,
        name: 'Employess',
        icon: <FontAwesome5 name="users" color={primaryColor} size={30} />,
    },
    {
        id: 5,
        name: 'Payroll',
        icon: <FontAwesome name="dollar" color={primaryColor} size={30} />,
    },
    {
        id: 6,
        name: 'Task',
        icon: <FontAwesome5 name="tasks" color={primaryColor} size={30} />,
    },
    {
        id: 7,
        name: 'Ticket List',
        icon: <FontAwesome5 name="sticky-note" color={primaryColor} size={30} />,
    },
    {
        id: 8,
        name: 'FSM',
        icon: <FontAwesome5 name="truck" color={primaryColor} size={30} />,
    },
    {
        id: 9,
        name: 'Warehouse',
        icon: <FontAwesome5 name="warehouse" color={primaryColor} size={30} />,
    },
    {
        id: 10,
        name: 'Purchasing',
        icon: <FontAwesome5 name="cart-plus" color={primaryColor} size={30} />,
    },
    {
        id: 11,
        name: 'Services',
        icon: <FontAwesome5 name="hands-helping" color={primaryColor} size={30} />,
    },
    {
        id: 11,
        name: 'Whatsapp',
        icon: <FontAwesome5 name="whatsapp" color={primaryColor} size={30} />,
    },
]