import { StyleSheet, Text, View, Image } from 'react-native'
import React,{useEffect} from 'react'
import { primaryColor } from '../../Constant'

export default function Jumbotron({partnerData}) {

  return (
       <View style={{
        backgroundColor: primaryColor,
        borderBottomEndRadius:50,
        borderBottomStartRadius:50,
        paddingBottom: 20,
      }}>
        {/* <View style={{ flexDirection: 'row', }}>
            <Text style={{ color: 'white', fontSize: 16, fontWeight: 'bold', marginLeft: 20, }}><Text style={{ fontSize: 16, fontWeight: 'normal'}}>Selamat malam</Text>, {partnerData.name}</Text>
        </View> */}
        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', padding: 17, }}>
          <Image
              style={styles.profileImgHeader}
              source={{
                uri: `data:image/png;base64,${partnerData.avatar_128}`
              }}
          />
          <View>
            <Text style={{ color: '#ffffff', fontSize: 14, fontWeight: 'bold'}}>{partnerData.name}</Text>
            <Text style={{ color: '#ffffff'}}>{partnerData.email}</Text>
          </View>
        </View>
      </View>
  )
}

const styles = StyleSheet.create({
    profileImgHeader: {
        width: 120,
        height: 120,
        borderRadius: 60,
        backgroundColor: 'white',
    },
  })