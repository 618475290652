import { SafeAreaView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../contexts/auth'
import { Button } from 'react-native-paper';
import { primaryColor } from '../Constant';
import LoadingScreen from './LoadingScreen';
import { AuthSession_objectGetData } from '../helper/session/AuthSession';
import Jumbotron from '../components/beranda/Jumbotron';
import MenuItem from '../components/beranda/MenuItem';
import { Searchbar } from 'react-native-paper';

import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { DataMenu } from '../data/DataMenu';

export default function Beranda() {
  const { signOut } = useAuth();
  
  const [loading_screen, set_loading_screen] = useState(true);
  const [partnerData, set_partnerData ] = useState('')
  const [filtered_menu, set_filtered_menu] = useState(DataMenu);
  // const [partnerData, set_partnerData ] = useState({
  //   avatar_128: '',
  //   name: '',
  //   email: ''
  // });

  const [searchQuery, setSearchQuery] = React.useState('');

  const onChangeSearch = query => {
    console.log('qeur',query)
    setSearchQuery(query)
  };

  
  useEffect( () => {
    retriveSession();
  }, [])
  
  useEffect( () => {
    const filteredItems = DataMenu.filter(item => 
        // item.name.toLowerCase() === searchQuery.toLowerCase()
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    set_filtered_menu(filteredItems);

  },[searchQuery])

  const retriveSession = async () => {
    set_loading_screen(true);
    const pd = await AuthSession_objectGetData('partnerData');
    // console.log('pd', pd[0])
    set_partnerData(pd[0]);
    set_loading_screen(false)
  }

  if ( loading_screen ) {
    return (
      <LoadingScreen />
    )
  }

  return (
    <SafeAreaView style={{
        flex: 1,
        // backgroundColor:'white'
        // justifyContent: 'center',
        // alignItems: 'center',
    }}>
      <Jumbotron partnerData={partnerData} />

      <View style={{ marginHorizontal: 17, marginTop: 15}}>
        <Searchbar
          placeholder="Search"
          onChangeText={onChangeSearch}
          value={searchQuery}
        />
      </View>
      <View style={{ flexDirection: 'row', padding: 17, flexWrap: 'wrap'}}>
        {
          filtered_menu.map((v,i) => {
            return (
              
              <MenuItem 
                key={i}
                name={v.name}
                icon={
                  v.icon
                }
              />
            )
          })
        }

      </View>


      <Button 
        style={{
          marginHorizontal: 17,
        }}
        buttonColor={primaryColor}
        mode='contained'
        // icon={'door-open'}
        onPress={ () => signOut()}
      >
        Logout
      </Button>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  menuItemContainer: {
    width: '25%',
    borderWidth: 1,
    alignItems: 'center',
    marginBottom: 15,
  }
})